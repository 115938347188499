import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { AlertTriangleIcon } from "lucide-react";
import "./RestrictionPopup.css";
import ChoosePlan from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan";

const RestrictionPopup = ({ onClose, showChoosePlan, setShowChoosePlan }) => {
  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="restriction-sub-modal"
    >
      <div className="restriction-sub-modal-content-wrapper">
        <div
          className="restriction-sub-modal-close-btn"
          onClick={() => {
            setShowChoosePlan(true);
            onClose();
          }}
        >
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="restriction-sub-modal-body-wrapper">
          <AlertTriangleIcon className="restriction-sub-modal-icon" />
          <div className="restriction-sub-modal-title">Upgrade Required</div>
          <div className="restriction-sub-modal-message">
            You need to upgrade your plan to upload more tracks.
          </div>
          <div className="restriction-sub-modal-actions">
            <button
              className="restriction-sub-action-button secondary"
              onClick={() => {
                // setShowChoosePlan(true);
                onClose();
              }}
            >
              Maybe Later
            </button>
            <button
              className="restriction-sub-action-button primary"
              onClick={() => {
                setShowChoosePlan(true);
                onClose();
              }}
            >
              Upgrade Plan
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RestrictionPopup;
