import React, { useEffect, useState } from "react";
import Styles from "./Header.module.css";
import search from "../../../assets/Images/Search.svg";
import upload from "../../../assets/Images/Upload.svg";
import ProfileDropdown from "./ProfileDropdown";
import UploadTrack from "../../Shared/UploadTrack/UploadTrack";
import NotifPanel from "../notif-panel/NotifPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  searchRequest,
  setSearchTextAction,
} from "../../../redux/actions/searchActions";
import { getNotificationRequest } from "../../../redux/actions";
import { RESET_SEARCH } from "../../../redux/actions/actionTypes";
import RestrictionPopup from "../restricted-popup/RestrictionPopup";
import { Modal } from "react-bootstrap";
import ChoosePlan from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan";
import PaymentPopup from "../payment-popup/PaymentPopup";
import ViewPlanSuccess from "../../../pages/MyProfilePage/ViewPlan/ViewPlanSuccess";
import { getAllArtistFetch } from "../../../redux/actions/smlActions";

export default function Header({ status }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [showRestricted, setShowRestricted] = useState(false);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [currentComponent, setCurrentComponent] = useState("plan");
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [index, setIndex] = useState();
  const [isYearly, setIsYearly] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);

  const artists =
    useSelector((store) => store.sml.allArtistFetched?.artists) || [];

  const remainingUploads = useSelector(
    (state) => state.uploadStats.statsFetched?.stats?.remainingUploads
  );
  const isActive = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats?.status
  );
  const uploadStats = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats
  );
  const togglePopup = () => {
    if (remainingUploads <= 0 || isActive !== "ACTIVE") {
      console.log("happening", remainingUploads);
      setShowRestricted(true);
      return
    }
    setPopupOpen(!isPopupOpen);
  };
  const onNextClick1 = (plan, index, isYearly) => {
    setSelectedPlanData(plan);
    setIndex(index);
    setIsYearly(isYearly);
    setCurrentComponent("payment");
  };
  const onNextClick2 = () => {
    setCurrentComponent("welcome");
  };
  const handleCloseViewPlan = () => {
    dispatch(getAllArtistFetch());
    setShowChoosePlan(false);
    setCurrentComponent("plan");
    setIndex(null);
    setSelectedPlanData(null);
  };
  const handleBack = () => {
    setCurrentComponent("plan");
  };

  const handleInputChange = (e) => {
    dispatch({ type: RESET_SEARCH });
    const value = e.target.value;
    setSearchText(value);

    dispatch(setSearchTextAction(value));

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (value.trim() !== "") {
      setTypingTimeout(
        setTimeout(() => {
          console.log("i am getting called", value);
          dispatch(searchRequest(value, status, 0, 10));
        }, 1000)
      );
    } else {
      // If searchText is empty, do not trigger search
      setTypingTimeout(null); // Clear any previous timeout if text is empty
    }
  };
  useEffect(() => {
    console.log("calling");
    // dispatch(getNotificationRequest({ offset: 0, limit: 10 }));
  }, [dispatch]);
  const websocketNotifications =
    useSelector((state) => state.websocket?.result) || [];
  const unread = useSelector(
    (state) => state.notifs?.notifs?.notifs?.unreadCount
  );

  const apiNotifications =
    useSelector((state) => state.notifs?.notifs?.notifs?.data) || [];

  const notifications =
    websocketNotifications.length > 0
      ? websocketNotifications
      : unread > 0
      ? apiNotifications
      : [];
  const isWebSocket = websocketNotifications.length > 0;
  // const notification = useSelector((state) => state.websocket?.result || []);

  return (
    <div className={Styles.container}>
      <div className={Styles.leftContainer}>
        <input
          onChange={handleInputChange}
          value={searchText}
          placeholder="Search"
        />
        <img
          onClick={() => {
            if (searchText.trim() !== "") {
              dispatch(searchRequest(searchText));
            }
          }}
          src={search}
          alt="Search"
        />
      </div>
      <div className={Styles.rightContainer}>
        <button
          onClick={togglePopup}
          style={{ backgroundColor: "transparent" }}
        >
          <img src={upload} alt="Cloud Arrow Up" />
        </button>
        {isPopupOpen && <UploadTrack onPopupClose={togglePopup} />}
        {showRestricted && (
        <>
          <RestrictionPopup
            showChoosePlan={showChoosePlan}
            setShowChoosePlan={setShowChoosePlan}
            onClose={() => {
              setShowRestricted(false);
            }}
          />
        </>
      )}
      {showChoosePlan && (
        <Modal
          className="custom-modal"
          show={true}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {currentComponent === "plan" && (
            <ChoosePlan
            planWhileCreating={
              isActive ? uploadStats?.plan : artists?.membership_plan
            }
            upgradePlan={isActive === "ACTIVE" ? "upgrade" : "choose"}
              closePopup={() => {
                setShowChoosePlan(false);
              }}
              onNextClick1={onNextClick1}
            />
          )}
          {currentComponent === "payment" && (
            <PaymentPopup
              selectedPlan={selectedPlanData}
              closePopup={handleCloseViewPlan}
              nextClick={onNextClick2}
              backClick={handleBack}
              forUpgrade={true}
              isYearly={isYearly}
            />
          )}
          {currentComponent === "welcome" && (
            <ViewPlanSuccess
              upgradePlan="upgrade"
              closePopup={handleCloseViewPlan}
            />
          )}
        </Modal>
      )}
        <NotifPanel
          panelType={"artist"}
          data={notifications}
          isWebSocket={isWebSocket}
        />
        <ProfileDropdown />
      </div>
    </div>
  );
}
