import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmMailRequest } from "../../../redux/actions";
import "./ConfirmMail.css";
import SMLButton from "../../Shared/SMLButton/SMLButton";
import { Modal } from "react-bootstrap";

//confirmation of mail registered
const ConfirmMail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, error, confirmed } = useSelector(
    (state) => state.confirmMail.confirmMail
  );

  const query = new URLSearchParams(useLocation().search);
  const username = query.get("email");
  const code = query.get("code");

  const decodedUsername = decodeURIComponent(username);

  useEffect(() => {
    if (decodedUsername && code) {
      dispatch(confirmMailRequest({ mail: decodedUsername, code }));
    }
  }, [dispatch, decodedUsername, code]);

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-pass-modal"
    >
      <div className="confirm-mail-container">
        <h1>Email Confirmation</h1>

        {isLoading && (
          <p className="loading-message">Confirming your email...</p>
        )}

        {!confirmed && (
          <div className="flex flex-column">
            <p>Your email has been successfully confirmed!</p>
            <div
              className="d-flex justify-content-center"
              onClick={handleHomeClick}
            >
              <SMLButton value="Home" style="SMLButton2" />
            </div>
          </div>
        )}

        {isError && (
          <div>
            {error === "User is already confirmed." ? (
              <p className="success-message">{error}</p>
            ) : (
              <p className="error-message">{error}</p>
            )}
            <div
              className="d-flex justify-content-center"
              onClick={handleHomeClick}
            >
              <SMLButton value="Home" style="SMLButton2" />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmMail;
