import React, { useEffect, useRef, useState } from "react";
import "./ParallaxPlan.css";
import "../../../styles/Fonts.scss";
import plans from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import SMLToggleButton from "../../Shared/SMLToggleButton/SMLToggleButton";
import bestValue from "../../../assets/Images/popular.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPlansRequest } from "../../../redux/actions";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const ParallaxPlan = () => {
  const dispatch = useDispatch();
  const [sendIndex, setSendIndex] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(sendIndex);
  const [selectedPlanData, setSelectedPlanData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  const containerRef = useRef(null);


  useGSAP(() => {
    // Heading animation
    gsap.fromTo(
      ".pricing-title",
      {
        opacity: 0,
        y: -40,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 80%",
        },
      }
    );

    // Toggle buttons animation
    gsap.fromTo(
      ".toggle-buttons",
      {
        opacity: 0,
        y: -40,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        // delay: 0.3,
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 80%",
        },
      }
    );

    // Plan cards animation

    gsap.fromTo(
      ".plan-card",
      {
        opacity: 0,
        filter: "blur(10px)",
        y: 40,
      },
      {
        opacity: 1,
        filter: "blur(0px)",
        y: 0,
        delay: 0.2,
        duration: 1,
        ease: "expo.out",
        stagger: 0.1,
        onComplete: () => {
          gsap.fromTo(
            ".best-value-image",
            {
              rotation: -5,
            },
            {
              rotation: 5,
              duration: 0.5,
              ease: "power1.inOut",
              yoyo: true,
              repeat: 1, // Infinite repeat

            }
          );
        },
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 80%",
        },
      }
    );

  }, { scope: containerRef }); // Empty dependency array means this will run once when component mounts

  useEffect(() => {
    dispatch(getPlansRequest()); // Fetch available plans from endpoint
  }, []);

  const plans1 = useSelector((state) => state.payment.getPlansReducer?.plans);

  //logic to match plan in json to the plan price in endpoint
  const findMatchingPlan = (plan) => {
    const priceId = isYearly ? plan.YearlyId : plan.monthlyId;
    console.log('Looking for plan with ID:', priceId);
    console.log('Available plans:', plans1);
    const matchedPlan = plans1.find((p1) => p1.id === priceId);
    console.log('Matched plan:', matchedPlan);
    return matchedPlan;
  };

  const handleCardClick = (index) => {
    setSelectedPlan(index);
    setSendIndex(index);
    setSelectedPlanData(findMatchingPlan(plans[index]));
    setShowPopup(true);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleIndex = (index, isYearly) => {
    setSelectedPlan(index);
    setIsYearly(isYearly);
  };

  const formatPrice = (priceString) => {
    if (!priceString) return { dollar: '', whole: '', decimal: '' };
    const price = priceString.substring(1); // Just remove the $ from start
    const [whole, decimal] = price.split('.');
    return {
      dollar: '$',
      whole: whole,
      decimal: decimal ? `.${decimal}` : ''
    };
  };

  return (
    <>
      {showPopup && (
        <SignUpPage
          onClose={handleClosePopup}
          selectedPlan={selectedPlanData}
          planIndex={sendIndex}
          isYearly={isYearly}
          fromHome={true}
          handleIndex={handleIndex}
        />
      )}
      <div className="parallaxPlan-container" ref={containerRef}>
        <div className="parallaxPlan-main">
          <div className="mains-container">
            <h2 className="heading pricing-title">
              We've got the right plan for you.
            </h2>
            <div className="toggle-buttons" onClick={stopPropagation}>
              <label className="title2">Monthly</label>
              <SMLToggleButton
                value={isYearly}
                checked={isYearly}
                onChange={() => {
                  setIsYearly(!isYearly);
                  setSelectedPlan(null);
                }}
              />
              <label className="title2">Yearly</label>
              <span className="title2">Save 20% over monthly</span>
            </div>
            <div className="plan-cards-container">
              {plans.map((plan, index) => (
                <div
                  key={index}
                  className={`plan-card ${selectedPlan === index ? "selected-card" : ""
                    }`}
                >
                  {plan.bestValue && (
                    <img
                      src={bestValue}
                      alt="Best Value"
                      className="best-value-image"
                    />
                  )}
                  <div className="plan-card-content">
                    <h2 className="title1">{plan.name}</h2>
                    {plan.price && (
                      <div className="price-container">
                        <p className="plan-price">
                          <span className="price-dollar">
                            {formatPrice(isYearly ? plan.yearlyPrice : plan.price).dollar}
                          </span>
                          <span className="price-whole">
                            {formatPrice(isYearly ? plan.yearlyPrice : plan.price).whole}
                          </span>
                          <span className="price-decimal">
                            {formatPrice(isYearly ? plan.yearlyPrice : plan.price).decimal}
                          </span>
                          <span className="plan-duration">
                            {isYearly ? "/year" : "/month"}
                          </span>
                        </p>
                      </div>
                    )}
                    <div className="friendlyName">
                      {plan.friendlyName || "\u00A0"}
                    </div>
                    <div className="horizontal-line"></div>
                    <ul className="features-list">
                      {plan.features.map((feature, i) => {
                        if (typeof feature === "string") {
                          return (
                            <li key={i} className="features-item">
                              <img
                                src={checkBox}
                                alt="checkbox"
                                className="checkbox-icon"
                              />
                              <span className="feature-text">{feature}</span>
                            </li>
                          );
                        } else if (typeof feature === "object") {
                          const [title, nestedFeatures] =
                            Object.entries(feature)[0];
                          return (
                            <React.Fragment key={i}>
                              <li className="features-item">
                                <img
                                  src={checkBox}
                                  alt="checkbox"
                                  className="checkbox-icon"
                                />
                                <span className="feature-text">{title}</span>
                              </li>
                              {nestedFeatures.map((nestedFeature, j) => (
                                <li
                                  key={`${i}-${j}`}
                                  className="features-item nested-feature-item"
                                >
                                  <span className="bullet-icon">•</span>
                                  <span className="feature-text">
                                    {nestedFeature}
                                  </span>
                                </li>
                              ))}
                            </React.Fragment>
                          );
                        }
                      })}
                    </ul>
                  </div>
                  {plan.price && (
                    <div onClick={() => handleCardClick(index)}>
                      <button className={`plan-button plan-button-${index}`}>
                      {selectedPlan === index ? "Current Plan" : plan.button}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParallaxPlan;
