import { combineReducers } from "redux";
import submissionsReducer from "./submissionsReducer";
import reviewsReducer from "./reviewsReducer";
import authReducer from "./authReducer";
import socialPostReducer from "./socialPostReducers";
import { singleArtistReducer } from "./artistReducers";
import { searchReducer } from "./searchReducers";
import trackStatsReducer from "./trackStatsReducer";
import uploadTrackReducer from "./uploadTrackReducer";
import smlReducer from "./smlReducers";
import { assignSupervisorReducer } from "./assignSupervisorReducer";
import {
  getFeedbackReducer,
  postFeedbackReducer,
  putFeedbackMsReducer,
  putFeedbackReducer,
  putFinalFeedbackMsReducer,
} from "./postFeedbackReducer";
import { downloadTrackReducer } from "./downloadTrackReducers";
import { supervisorReducer } from "./supervisorReducers";
import { paymentReducer } from "./paymentReducers";
import { forgetPassReducer } from "./forgetPasswordReducers";
import { confirmMailReducer } from "./confirmMailReducers";
import { websocketReducers } from "./websocketReducers";
import { getNotificationReducers } from "./notificationReducers";
import videoReducer from './videoReducer';
import uploadStatsReducer from "./uploadStatsReducer";

const rootReducer = combineReducers({
  submissions: submissionsReducer,
  reviews: reviewsReducer,
  auth: authReducer,
  search: searchReducer,
  trackStats: trackStatsReducer,
  artist: singleArtistReducer,
  sml: smlReducer,
  assignSupervisor: assignSupervisorReducer,
  getFeedback: getFeedbackReducer,
  postFeedback: postFeedbackReducer,
  putFeedback: putFeedbackReducer,
  putFeedbackMs: putFeedbackMsReducer,
  putFinalFeedbackMs: putFinalFeedbackMsReducer,
  downloadTrack: downloadTrackReducer,
  supervisor: supervisorReducer,
  payment: paymentReducer,
  forgetPass: forgetPassReducer,
  confirmMail: confirmMailReducer,
  websocket: websocketReducers,
  notifs: getNotificationReducers,
  video: videoReducer,
  uploadStats : uploadStatsReducer
});

// Add this debug wrapper
const wrappedRootReducer = (state, action) => {
  const nextState = rootReducer(state, action);
  return nextState;
};

export default wrappedRootReducer;
