import React, { useEffect, useState } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import StyledHeader from "../../components/common/styled-components/StyledHeader";
import Styles from "./Dashboard.module.css";
import BannerImage from "../../assets/Images/banner-image.svg";
import RightArrow from "../../assets/Icons/right-arrow.svg";
import UploadIcon from "../../assets/Icons/upload-icon.svg";
import SMLCard from "../../components/common/styled-components/SMLCard";
import SMLCard2 from "../../components/common/styled-components/SMLCard2";
import TrackIcon from "../../assets/Icons/track-icon.svg";
import FeedbackIcon from "../../assets/Icons/feedback.svg";
import ReviewTrackIcon from "../../assets/Icons/review-tracks.svg";
import UploadedTracksIcon from "../../assets/Icons/uploaded-tracks.svg";
import UnderReviewIcon from "../../assets/Icons/under-review.svg";
import UploadTrack from "../../components/Shared/UploadTrack/UploadTrack";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import ReactPlayer from "react-player";
import video from "../../assets/video/ShannonIntro12.mp4";
import thumnail from "../../assets/Images/thumbnail.png";
import {
  feedbackReceivedFetch,
  getAllArtistFetch,
  inReviewTracksFetch,
} from "../../redux/actions/smlActions";
import { getTrackStats } from "../../redux/actions/trackStats";
import { getAllTracksFetch } from "../../redux/actions/smlActions";
import {
  GET_UPLOAD_STATS_REQUEST,
  RESET_GET_ALL_TRACK,
  RESET_SEARCH,
} from "../../redux/actions/actionTypes";
import { useNavigate } from "react-router-dom";
import { artistSignupRemove } from "../../redux/actions/authActions";
import { websocketConnect } from "../../redux/actions/websocketActions";
import { Tooltip } from "react-tooltip";
import RestrictionPopup from "../../components/common/restricted-popup/RestrictionPopup";
import ChoosePlan from "../SignUpPage/ChoosePlan/ChoosePlan";
import { Modal } from "react-bootstrap";
import PaymentPopup from "../../components/common/payment-popup/PaymentPopup";
import ViewPlanSuccess from "../MyProfilePage/ViewPlan/ViewPlanSuccess";
export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showRestricted, setShowRestricted] = useState(false);
  const [stats, setStats] = useState(null);
  const [track, setTrack] = useState(null);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [index, setIndex] = useState();
  const [isYearly, setIsYearly] = useState("");
  const [currentComponent, setCurrentComponent] = useState("plan");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [canUpload, setCanUpload] = useState(true);
  const handleTrackChange = (e) => {
    setTrack(e.target.files[0]);
  };

  useEffect(() => {
    dispatch({ type: GET_UPLOAD_STATS_REQUEST });
    dispatch({ type: RESET_SEARCH });
    dispatch({ type: RESET_GET_ALL_TRACK });
    dispatch(getAllTracksFetch("submitted", "artist_id", 0, 5));
    dispatch(inReviewTracksFetch("in_review", "artist_id", 0, 5));
    dispatch(
      feedbackReceivedFetch("final_feedback_submitted", "artist_id", 0, 5)
    );
    dispatch(artistSignupRemove());
  }, [dispatch]);
  const uploadStats = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats
  );
  const remainingUploads = useSelector(
    (state) => state.uploadStats.statsFetched?.stats?.remainingUploads
  );
  const isActive = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats?.status
  );
  const uploadStatsLoading = useSelector(
    (state) => state.uploadStats?.statsFetched?.isLoading
  );
  useEffect(() => {
    if (uploadStatsLoading) return;
    const isRestricted = isActive !== "ACTIVE";
    setCanUpload(!isRestricted);
    setShowRestricted(isRestricted);
  }, [isActive, uploadStatsLoading]);

  const togglePopup = () => {
    if (remainingUploads <= 0 || isActive !== "ACTIVE") {
      console.log("happening", remainingUploads);
      setCanUpload(false);
      setShowRestricted(true);
      return;
    }
    setPopupOpen(!isPopupOpen);
  };

  const submittedTracks = useSelector(
    (store) => store.sml.allTracksFetched?.tracks
  );
  const inReviewTracks = useSelector(
    (store) => store.sml.underReviewTracks?.tracks?.data
  );
  const feedbackReceievedTracks = useSelector(
    (store) => store.sml.feedbackReceivedTracks?.tracks?.data
  );
  const submittedTracksLoading = useSelector(
    (store) => store.sml.allTracksFetched?.isLoading
  );
  const inReviewTracksLoading = useSelector(
    (store) => store.sml.underReviewTracks?.isLoading
  );
  const feedbackReceievedTracksLoading = useSelector(
    (store) => store.sml.feedbackReceivedTracks?.isLoading
  );

  const sml = useSelector((store) => store.sml);
  const trackStats = useSelector(
    (store) => store.trackStats.statsFetched?.stats
  );
  const loading = trackStats ? false : true;

  useEffect(() => {
    dispatch(getTrackStats("artist"));
  }, [dispatch]);

  const artists =
    useSelector((store) => store.sml.allArtistFetched?.artists) || [];

  useEffect(() => {
    if (artists.length === 0) {
      dispatch(getAllArtistFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    if (trackStats) {
      setStats(trackStats);
    }
  }, [trackStats]);
  const handleUploadedTrackClick = () => {
    navigate("/uploadedTrack");
  };
  const handleInReviewClick = () => {
    navigate("/Review");
  };
  const handleFeedbackSubmittedClick = () => {
    navigate("/FeedbackReceived");
  };
  const onNextClick1 = (plan, index, isYearly) => {
    setSelectedPlanData(plan);
    setIndex(index);
    setIsYearly(isYearly);
    setCurrentComponent("payment");
  };
  const onNextClick2 = () => {
    setCurrentComponent("welcome");
  };
  const handleCloseViewPlan = () => {
    dispatch(getAllArtistFetch());
    setShowChoosePlan(false);
    setCurrentComponent("plan");
    setIndex(null);
    setSelectedPlanData(null);
  };
  const handleBack = () => {
    setCurrentComponent("plan");
  };
  return (
    <HomeLayout applyScroll={true}>
      <StyledHeader>Dashboard</StyledHeader>
      <div className={Styles.dashboardContainer}>
        <BannerCard artists={artists} />
        <UploadCard onClick={togglePopup} />
        {isPopupOpen && <UploadTrack onPopupClose={togglePopup} />}

        <VideoCard />

        <div className={Styles.dashboardSubContainer}>
          <StatusCard
            onClick={handleUploadedTrackClick}
            imageSrc={UploadedTracksIcon}
            label="Uploaded Tracks"
            stats={stats?.submitted}
          />
          {submittedTracks && (
            <TracksCard
              tracks={submittedTracks}
              status="submitted"
              loadingTracks={submittedTracksLoading}
            />
          )}
          <StatusCard
            onClick={handleInReviewClick}
            imageSrc={ReviewTrackIcon}
            label="Tracks Under Review !"
            stats={stats?.under_review}
          />
          <TracksCard
            tracks={inReviewTracks}
            status="under review"
            loadingTracks={inReviewTracksLoading}
          />
          <StatusCard
            onClick={handleFeedbackSubmittedClick}
            imageSrc={FeedbackIcon}
            label="Feedback Received"
            stats={stats?.feedback_received}
          />
          <TracksCard
            tracks={feedbackReceievedTracks}
            status="feedback received"
            loadingTracks={feedbackReceievedTracksLoading}
          />
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!uploadStatsLoading && showRestricted && (
        <>
          <RestrictionPopup
            showChoosePlan={showChoosePlan}
            setShowChoosePlan={setShowChoosePlan}
            onClose={() => {
              setShowRestricted(false);
            }}
          />
        </>
      )}
      {showChoosePlan && (
        <Modal
          className="custom-modal"
          show={true}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {currentComponent === "plan" && (
            <ChoosePlan
              planWhileCreating={
                isActive ? uploadStats?.plan : artists?.membership_plan
              }
              upgradePlan={isActive === "ACTIVE" ? "upgrade" : "choose"}
              closePopup={() => {
                setShowChoosePlan(false);
              }}
              onNextClick1={onNextClick1}
            />
          )}
          {currentComponent === "payment" && (
            <PaymentPopup
              selectedPlan={selectedPlanData}
              closePopup={handleCloseViewPlan}
              nextClick={onNextClick2}
              backClick={handleBack}
              forUpgrade={true}
              isYearly={isYearly}
            />
          )}
          {currentComponent === "welcome" && (
            <ViewPlanSuccess
              upgradePlan="upgrade"
              closePopup={handleCloseViewPlan}
            />
          )}
        </Modal>
      )}
    </HomeLayout>
  );
}

const BannerCard = ({ artists }) => {
  return (
    <SMLCard className={Styles.bannerContainer}>
      <img
        alt="banner"
        src={BannerImage}
        className={Styles.bannerBackgroundImage}
      />
      <div className="bannerContainerHeading">
        <h3>
          {artists?.name}
          {" hub"}
        </h3>
      </div>
      <div>
        <img alt="right arrow" src={RightArrow} />
      </div>
    </SMLCard>
  );
};

const UploadCard = ({ onClick }) => {
  return (
    <SMLCard className={Styles.uploadContainer} onClick={onClick}>
      <div onClick={onClick}>
        <img alt="upload" src={UploadIcon} onClick={onClick} />
      </div>
      <div>
        <h2>Upload New Track</h2>
      </div>
    </SMLCard>
  );
};

const VideoCard = () => {
  const playerWrapperRef = React.useRef(null);

  React.useEffect(() => {
    const resizePlayerWrapper = () => {
      if (playerWrapperRef.current) {
        const width = playerWrapperRef.current.offsetWidth;
        playerWrapperRef.current.style.height = `${width * (9 / 16)}px`;
      }
    };

    resizePlayerWrapper();
    window.addEventListener("resize", resizePlayerWrapper);

    return () => {
      window.removeEventListener("resize", resizePlayerWrapper);
    };
  }, []);

  return (
    <SMLCard className={Styles.videoContainer}>
      <div ref={playerWrapperRef} className={Styles.playerWrapper}>
        <ReactPlayer
          playing={true}
          url={video}
          light={thumnail}
          className={Styles.reactPlayer}
          width="100%"
          height="100%"
          controls
        />
      </div>
      <div className={Styles.videoInfo}>
        <h6>Introduction</h6>
        <h4>SML in a Nutshell</h4>
      </div>
    </SMLCard>
  );
};

const RecentActivity = () => {
  return (
    <SMLCard className={Styles.recentActivityContainer}>
      <div>
        <h6>Recent Activity</h6>
        <h2>You Submitted a Task For Review.</h2>
      </div>
      <div>
        <div>
          <h6>Track</h6>
          <h4>Name of Track</h4>
        </div>
        <div>
          <h6>Status</h6>
          <h4>
            Under Review <img src={UnderReviewIcon} alt="under review"></img>
          </h4>
        </div>
      </div>
    </SMLCard>
  );
};

const StatusCard = ({ imageSrc, label, stats, onClick }) => {
  return (
    <SMLCard className={Styles.statusCard} onClick={onClick}>
      <img src={imageSrc} alt="statsImage" />
      <h2>{label}</h2>
      <h3>{stats}</h3>
    </SMLCard>
  );
};

const TracksCard = ({ secondaryLabel, tracks, status, loadingTracks }) => {
  const navigate = useNavigate();
  const handleTrackClick = (trackId, status) => {
    if (status !== "final_feedback_submitted") {
      navigate(`/TrackDetails/${trackId}`, {
        state: { fromUserPortal: true, status, fromDashboard: true },
      });
    } else {
      navigate(`/TrackDetailsFinal/${trackId}`, {
        state: { status, fromDashboard: true },
      });
    }
  };
  return (
    <SMLCard2 className={Styles.tracksContainer}>
      {Array.isArray(tracks) && tracks.length > 0 ? (
        <>
          {tracks.map((item, index) => {
            const isOverflowing = (element) => {
              return element.offsetWidth < element.scrollWidth;
            };

            return (
              <div
                className={Styles.trackRow}
                key={item?.key || index}
                onClick={() => {
                  handleTrackClick(item._id, item.status.code);
                }}
              >
                <img src={TrackIcon} alt="trackIcon" />
                <h2
                  ref={(el) => {
                    if (el && isOverflowing(el)) {
                      el.setAttribute("data-tooltip-content", item.title);
                    }
                  }}
                >
                  {item?.title.length > 40 ? (
                    <>
                      {item?.title.substring(0, 40)}...
                      <Tooltip id="trackTitle-details-final" />
                    </>
                  ) : (
                    item?.title
                  )}
                </h2>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {!loadingTracks && (
            <h5 className={Styles.tracksEmptyPlaceholder}>
              You do not have any {status} tracks.
            </h5>
          )}
        </>
      )}

      <h6 className={Styles.tracksSecondaryText}>{secondaryLabel}</h6>
    </SMLCard2>
  );
};
