// Artist Submissions
export const FETCH_SUBMISSIONS_REQUEST = "FETCH_SUBMISSIONS_REQUEST";
export const FETCH_SUBMISSIONS_SUCCESS = "FETCH_SUBMISSIONS_SUCCESS";
export const FETCH_SUBMISSIONS_FAILURE = "FETCH_SUBMISSIONS_FAILURE";
export const ADD_SUBMISSION_REQUEST = "ADD_SUBMISSION_REQUEST";
export const ADD_SUBMISSION_SUCCESS = "ADD_SUBMISSION_SUCCESS";
export const ADD_SUBMISSION_FAILURE = "ADD_SUBMISSION_FAILURE";

// Supervisor Reviews
export const FETCH_REVIEWS_REQUEST = "FETCH_REVIEWS_REQUEST";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_FAILURE = "FETCH_REVIEWS_FAILURE";
export const ADD_REVIEW_REQUEST = "ADD_REVIEW_REQUEST";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
export const ADD_REVIEW_FAILURE = "ADD_REVIEW_FAILURE";

// User Authentication
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

//signup
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const ARTIST_SIGNUP_REMOVE = "ARTIST_SIGNUP_REMOVE";
export const ARTIST_SHOW_LOGIN = "ARTIST_SHOW_LOGIN";
export const SUP_SIGNUP_REMOVE = "SUP_SIGNUP_REMOVE";
export const SUP_SHOW_LOGIN = "SUP_SHOW_LOGIN";

//sup signup
export const SUP_SIGNUP_REQUEST = "SUP_SIGNUP_REQUEST";
export const SUP_SIGNUP_SUCCESS = "SUP_SIGNUP_SUCCESS";
export const SUP_SIGNUP_FAILURE = "SUP_SIGNUP_FAILURE";
//reset password request
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_REMOVE = "RESET_PASSWORD_REMOVE";

//sml file actions
export const GET_UNDER_REVIEW_TRACKS_SUCESS = "GET_UNDER_REVIEW_TRACKS_SUCCESS";
export const GET_UNDER_REVIEW_TRACKS_FAILURE =
  "GET_UNDER_REVIEW_TRACKS_FAILURE";
export const GET_UNDER_REVIEW_TRACKS_FETCH = "GET_UNDER_REVIEW_TRACKS_FETCH";
export const GET_UNDER_REVIEW_TRACKS_LOADING =
  "GET_UNDER_REVIEW_TRACKS_LOADING";

export const GET_FEEDBACK_RECEIVED_TRACKS_SUCESS =
  "GET_FEEDBACK_RECEIVED_TRACKS_SUCCESS";
export const GET_FEEDBACK_RECEIVED_TRACKS_FAILURE =
  "GET_FEEDBACK_RECEIVED_TRACKS_FAILURE";
export const GET_FEEDBACK_RECEIVED_TRACKS_FETCH =
  "GET_FEEDBACK_RECEIVED_TRACKS_FETCH";
export const GET_FEEDBACK_RECEIVED_TRACKS_LOADING =
  "GET_FEEDBACK_RECEIVED_TRACKS_LOADING";
export const GET_ALL_ARTIST_FETCH = "GET_ALL_ARTIST_FETCH";
export const GET_ALL_ARTIST_SUCCESS = "GET_ALL_ARTIST_SUCCESS";
export const GET_ALL_ARTIST_FAILURE = "GET_ALL_ARTIST_FAILURE";
export const GET_ALL_ARTIST_LOADING = "GET_ALL_ARTIST_LOADING";

export const GET_ALL_TRACK_FETCH = "GET_ALL_TRACK_FETCH";
export const GET_ALL_TRACK_SUCCESS = "GET_ALL_TRACK_SUCCESS";
export const GET_ALL_TRACK_PAGINATION_SUCCESS =
  "GET_ALL_TRACK_PAGINATION_SUCCESS";
export const GET_ALL_TRACK_FAILURE = "GET_ALL_TRACK_FAILURE";
export const GET_ALL_TRACK_LOADING = "GET_ALL_TRACK_LOADING";
export const RESET_GET_ALL_TRACK = "RESET_GET_ALL_TRACK";
//upload track
export const GET_TRACK_REQUEST = "GET_TRACK_REQUEST";
export const GET_TRACK_SUCCESS = "GET_TRACK_SUCCESS";
export const GET_TRACK_FAILURE = "GET_TRACK_FAILURE";

export const GET_SINGLE_TRACK_REQUEST = "GET_SINGLE_TRACK_REQUEST";
export const GET_SINGLE_TRACK_SUCCESS = "GET_SINGLE_TRACK_SUCCESS";
export const GET_SINGLE_TRACK_FAILURE = "GET_SINGLE_TRACK_FAILURE";
export const RESET_GET_SINGLE_TRACK = "RESET_GET_SINGLE_TRACK";

export const POST_TRACK_REQUEST = "POST_TRACK_REQUEST";
export const POST_TRACK_SUCCESS = "POST_TRACK_SUCCESS";
export const POST_TRACK_FAILURE = "POST_TRACK_FAILURE";
export const POST_TRACK_LOADING = "POST_TRACK_LOADING";

export const RESET_UPLOAD_TRACK = "RESET_UPLOAD_TRACK";

export const UPDATE_TRACK_REQUEST = "UPDATE_TRACK_REQUEST";
export const UPDATE_TRACK_SUCCESS = "UPDATE_TRACK_SUCCESS";
export const UPDATE_TRACK_FAILURE = "UPDATE_TRACK_FAILURE";

export const DELETE_TRACK_REQUEST = "DELETE_TRACK_REQUEST";
export const DELETE_TRACK_SUCCESS = "DELETE_TRACK_SUCCESS";
export const DELETE_TRACK_FAILURE = "DELETE_TRACK_FAILURE";

//social api
export const POST_SOCIAL_REQUEST = "POST_SOCIAL_REQUEST";
export const POST_SOCIAL_SUCCESS = "POST_SOCIAL_SUCCESS";
export const POST_SOCIAL_FAILURE = "POST_SOCIAL_FAILURE";

//social login api
export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";
//artist social login api
export const SOCIAL_SIGNUP_REQUEST = "SOCIAL_SIGNUP_REQUEST";
export const SOCIAL_SIGNUP_SUCCESS = "SOCIAL_SIGNUP_SUCCESS";
export const SOCIAL_SIGNUP_FAILURE = "SOCIAL_SIGNUP_FAILURE";
//sueprvisor social login api
export const SUP_SOCIAL_SIGNUP_REQUEST = "SUP_SOCIAL_SIGNUP_REQUEST";
export const SUP_SOCIAL_SIGNUP_SUCCESS = "SUP_SOCIAL_SIGNUP_SUCCESS";
export const SUP_SOCIAL_SIGNUP_FAILURE = "SUP_SOCIAL_SIGNUP_FAILURE";
//search api
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE ";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_RESPONSE_SUCCESS = "SEARCH_RESPONSE_SUCCESS";
export const RESET_SEARCH = "RESET_SEARCH";

//search text
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";

// Track stats
export const GET_TRACK_STATS = "GET_TRACK_REQUEST";
export const GET_TRACK_STATS_SUCCESS = "GET_TRACK_SUCCESS";
export const GET_TRACK_STATS_FAILURE = "GET_TRACK_FAILURE";

// Update Artist
export const UPDATE_ARTIST_REQUEST = "UPDATE_ARTIST_REQUEST";
export const UPDATE_ARTIST_SUCCESS = "UPDATE_ARTIST_SUCCESS";
export const UPDATE_ARTIST_FAILURE = "UPDATE_ARTIST_FAILURE";

//assign supervisor to track by id
export const ASSIGN_SUPERVISOR_REQUEST = "ASSIGN_SUPERVISOR_REQUEST";
export const ASSIGN_SUPERVISOR_LOADING = "ASSIGN_SUPERVISOR_LOADING";
export const ASSIGN_SUPERVISOR_SUCCESS = "ASSIGN_SUPERVISOR_SUCCESS";
export const ASSIGN_SUPERVISOR_FAILURE = "ASSIGN_SUPERVISOR_FAILURE";
export const RESET_ASSIGNED_SUPERVISOR = "RESET_ASSIGNED_SUPERVISOR";
//post feedback
export const POST_FEEDBACK_REQUEST = "POST_FEEDBACK_REQUEST";
export const POST_FEEDBACK_LOADING = "POST_FEEDBACK_LOADING";
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS";
export const POST_FEEDBACK_FAILURE = "POST_FEEDBACK_FAILURE";

//put feedback
export const PUT_FEEDBACK_REQUEST = "PUT_FEEDBACK_REQUEST";
export const PUT_FEEDBACK_LOADING = "PUT_FEEDBACK_LOADING";
export const PUT_FEEDBACK_SUCCESS = "PUT_FEEDBACK_SUCCESS";
export const PUT_FEEDBACK_FAILURE = "PUT_FEEDBACK_FAILURE";

//get feedback by id
export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_LOADING = "GET_FEEDBACK_LOADING";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILURE = "GET_FEEDBACK_FAILURE";

export const RESET_GET_FEEDBACK = "RESET_GET_FEEDBACK ";

//put feedback mastersupervisor
export const PUT_FEEDBACK_MS_REQUEST = "PUT_FEEDBACK_MS_REQUEST";
export const PUT_FEEDBACK_MS_LOADING = "PUT_FEEDBACK_MS_LOADING";
export const PUT_FEEDBACK_MS_SUCCESS = "PUT_FEEDBACK_MS_SUCCESS";
export const PUT_FEEDBACK_MS_FAILURE = "PUT_FEEDBACK_MS_FAILURE";
//put final feedback mastersupervisor
export const PUT_FINAL_FEEDBACK_MS_REQUEST = "PUT_FINAL_FEEDBACK_MS_REQUEST";
export const PUT_FINAL_FEEDBACK_MS_LOADING = "PUT_FINAL_FEEDBACK_MS_LOADING";
export const PUT_FINAL_FEEDBACK_MS_SUCCESS = "PUT_FINAL_FEEDBACK_MS_SUCCESS";
export const PUT_FINAL_FEEDBACK_MS_FAILURE = "PUT_FINAL_FEEDBACK_MS_FAILURE";

//feedback reset
export const RESET_POST_FEEDBACK_ASSIGNED = "RESET_POST_FEEDBACK_ASSIGNED";
export const RESET_PUT_FEEDBACK_ASSIGNED = "RESET_PUT_FEEDBACK_ASSIGNED";
export const RESET_PUT_FEEDBACK_MS_ASSIGNED = "RESET_PUT_FEEDBACK_MS_ASSIGNED";
export const RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED =
  "RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED ";

//download track
export const DOWNLOAD_TRACK_REQUEST = "DOWNLOAD_TRACK_REQUEST";
export const DOWNLOAD_TRACK_SUCCESS = "DOWNLOAD_TRACK_SUCCESS";
export const DOWNLOAD_TRACK_FAILURE = "DOWNLOAD_TRACK_FAILURE";
export const DOWNLOAD_TRACK_LOADING = "DOWNLOAD_TRACK_LOADING";
export const RESET_DOWNLOAD_TRACK = "RESET_DOWNLOAD_TRACK";

//get
export const GET_SINGLE_ARTIST_REQUEST = "GET_SINGLE_ARTIST_REQUEST";
export const GET_SINGLE_ARTIST_SUCCESS = "GET_SINGLE_ARTIST_SUCCESS";
export const GET_SINGLE_ARTIST_FAILURE = "GET_SINGLE_ARTIST_FAILURE";
export const GET_SINGLE_ARTIST_LOADING = "GET_SINGLE_ARTIST_LOADING";
export const RESET_SINGLE_ARTIST = "RESET_DOWNLOAD_TRACK";

//refresh token request
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

//get supervisors
export const GET_SUPERVISOR_REQUEST = "GET_SUPERVISOR_REQUEST";
export const GET_SUPERVISOR_SUCCESS = "GET_SUPERVISOR_SUCCESS";
export const GET_SUPERVISOR_FAILURE = "GET_SUPERVISOR_FAILURE";
export const GET_SUPERVISOR_LOADING = "GET_SUPERVISOR_LOADING";

//get config
export const GET_PB_KEY_REQUEST = "GET_PB_KEY_REQUEST";
export const GET_PB_KEY_SUCCESS = "GET_PB_KEY_SUCCESS";
export const GET_PB_KEY_FAILURE = "GET_PB_KEY_FAILURE";
export const GET_PB_KEY_LOADING = "GET_PB_KEY_LOADING";
export const RESET_PB_KEY = "RESET_PB_KEY";
//get plan
export const GET_PLANS_REQUEST = "GET_PLANS_REQUEST";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILURE = "GET_PLANS_FAILURE";
export const GET_PLANS_LOADING = "GET_PLANS_LOADING";

//get client secret
export const GET_CLIENT_SECRET_REQUEST = "GET_CLIENT_SECRET_REQUEST";
export const GET_CLIENT_SECRET_SUCCESS = "GET_CLIENT_SECRET_SUCCESS";
export const GET_CLIENT_SECRET_FAILURE = "GET_CLIENT_SECRET_FAILURE";
export const GET_CLIENT_SECRET_LOADING = "GET_CLIENT_SECRET_LOADING";
export const RESET_GET_CLIENT = "RESET_GET_CLIENT";
//create sub
export const CREATE_SUB_REQUEST = "CREATE_SUB_REQUEST";
export const CREATE_SUB_SUCCESS = "CREATE_SUB_SUCCESS";
export const CREATE_SUB_FAILURE = "CREATE_SUB_FAILURE";
export const CREATE_SUB_LOADING = "CREATE_SUB_LOADING";
export const RESET_CREATE_SUB = "RESET_CREATE_SUB";
//cancel sub
export const CANCEL_SUB_LOADING = "CANCEL_SUB_LOADING";
export const CANCEL_SUB_REQUEST = "CANCEL_SUB_REQUEST";
export const CANCEL_SUB_SUCCESS = "CANCEL_SUB_SUCCESS";
export const CANCEL_SUB_FAILURE = "CANCEL_SUB_FAILURE";
export const RESET_CANCEL_SUB = "RESET_CANCEL_SUB";

//forget password
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const FORGET_PASSWORD_LOADING = "FORGET_PASSWORD_LOADING";

//forgetfinal
export const FORGET_FINAL_PASSWORD_REQUEST = "FORGET_FINAL_PASSWORD_REQUEST";
export const FORGET_FINAL_PASSWORD_SUCCESS = "FORGET_FINAL_PASSWORD_SUCCESS";
export const FORGET_FINAL_PASSWORD_FAILURE = "FORGET_FINAL_PASSWORD_FAILURE";
export const FORGET_FINAL_PASSWORD_LOADING = "FORGET_FINAL_PASSWORD_LOADING";

//reset
export const RESET_FORGET_PASSWORD = "RESET_FORGET_PASSWORD";

//confirm mail
export const CONFIRM_MAIL_REQUEST = "CONFIRM_EMAIL_REQUEST";
export const CONFIRM_MAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_MAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";
export const CONFIRM_MAIL_LOADING = "CONFIRM_EMAIL_LOADING";
export const RESET_CONFIRM_MAIL = "RESET_CONFIRM_EMAIL";

//selected plan
export const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";

//web socket actions
export const WEBSOCKET_REQUEST = "WEBSOCKET_REQUEST";
export const WEBSOCKET_LOADING = "WEBSOCKET_LOADING";
export const WEBSOCKET_SUCCESS = "WEBSOCKET_SUCCESS";
export const WEBSOCKET_ERROR = "WEBSOCKET_ERROR";
export const RESET_WEBSOSCKET_RESULT = "RESET_WEBSOSCKET_RESULT";

//Schedule Live Consultation
export const VIDEO_CONSULTATION_REQUEST = "VIDEO_CONSULTATION_REQUEST";
export const VIDEO_CONSULTATION_SUCCESS = "VIDEO_CONSULTATION_SUCCESS";
export const VIDEO_CONSULTATION_FAILURE = "VIDEO_CONSULTATION_FAILURE";
export const VIDEO_CONSULTATION_LOADING = "VIDEO_CONSULTATION_LOADING";

//get notifications
export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
export const GET_NOTIFICATION_RESET = "GET_NOTIFICATION_RESET";
export const GET_NOTIFICATION_RESPONSE_SUCCESS =
  "GET_NOTIFICATION_RESPONSE_SUCCESS";
export const RESET_NOTIFICATION_SET = "RESET_NOTIFICATION_SET";

//update notifications
export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";
export const UPDATE_NOTIFICATION_RESET = "UPDATE_NOTIFICATION_RESET";

// Add these new action types
export const GET_VIDEO_SIGNED_URL_REQUEST = "GET_VIDEO_SIGNED_URL_REQUEST";
export const GET_VIDEO_SIGNED_URL_SUCCESS = "GET_VIDEO_SIGNED_URL_SUCCESS";
export const GET_VIDEO_SIGNED_URL_FAILURE = "GET_VIDEO_SIGNED_URL_FAILURE";

export const GET_VIDEO_URL_REQUEST = "GET_VIDEO_URL_REQUEST";
export const GET_VIDEO_URL_SUCCESS = "GET_VIDEO_URL_SUCCESS";
export const GET_VIDEO_URL_FAILURE = "GET_VIDEO_URL_FAILURE";
export const RESET_VIDEO_URL = "RESET_VIDEO_URL";

export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";

export const UPLOAD_VIDEO_REQUEST = "UPLOAD_VIDEO_REQUEST";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAILURE = "UPLOAD_VIDEO_FAILURE";

// Add these if they don't exist
export const GET_VIDEO_SIGNED_URL_LOADING = "GET_VIDEO_SIGNED_URL_LOADING";
export const GET_VIDEO_URL_LOADING = "GET_VIDEO_URL_LOADING";
export const UPLOAD_VIDEO_LOADING = "UPLOAD_VIDEO_LOADING";
export const DELETE_VIDEO_LOADING = "DELETE_VIDEO_LOADING";

//upload stats
export const GET_UPLOAD_STATS_REQUEST = "GET_UPLOAD_STATS_REQUEST";
export const GET_UPLOAD_STATS_SUCCESS = "GET_UPLOAD_STATS_SUCCESS";
export const GET_UPLOAD_STATS_FAILURE = "GET_UPLOAD_STATS_FAILURE";
export const GET_UPLOAD_STATS_LOADING = "GET_UPLOAD_STATS_LOADING";
export const RESET_GET_UPLOAD_STATS = "RESET_GET_UPLOAD_STATS";
