import {
  GET_UPLOAD_STATS_FAILURE,
  GET_UPLOAD_STATS_REQUEST,
  GET_UPLOAD_STATS_SUCCESS,
  RESET_GET_UPLOAD_STATS,
} from "../actions/actionTypes";

const initialState = {
  statsFetched: {
    isLoading: false,
    isError: false,
    stats: [],
    error: null,
  },
};

const uploadStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPLOAD_STATS_REQUEST:
      return {
        ...state,
        statsFetched: {
          isLoading: true,
          isError: false,
          error: null,
        },
      };

    case GET_UPLOAD_STATS_SUCCESS:
      return {
        ...state,
        statsFetched: {
          isLoading: false,
          stats: action.stats,
        },
      };

    case GET_UPLOAD_STATS_FAILURE:
      return {
        ...state,
        statsFetched: {
          isLoading: false,
          isError: true,
          error: action.payload,
        },
      };
    case RESET_GET_UPLOAD_STATS:
      return {
        statsFetched: {
          isLoading: false,
          isError: false,
          stats: [],
          error: undefined,
        },
      };
    default:
      return state;
  }
};

export default uploadStatsReducer;
