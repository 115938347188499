import { useEffect } from 'react';

const GoogleTagManager = ({ gtmId = 'GTM-KJ7TX65Q' }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    // Determine environment based on URL
    let environment = 'production';
    if (window.location.hostname === 'localhost') {
      environment = 'local';
    } else if (window.location.hostname.includes('sml-dev')) {
      environment = 'staging';
    }

    window.dataLayer.push({
      'environment': environment
    });

    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  }, [gtmId]);

  return null;
};

export default GoogleTagManager;